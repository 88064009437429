import * as React from 'react'
import * as DateFns from 'date-fns'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Inventory from './inventory'
import * as Theming from './theming'
import * as Icons from './icons'
import { Button } from './button'
import { Divider } from './divider'
import { HtmlContent } from './html-content'

function getTimeLeftForPromotion(props) {
  const expiresAt = DateFns.parseISO(props.expiresAt)
  const activeFrom = DateFns.parseISO(props.activeFrom)

  const timeData = [
    {
      id: 'days',
      availableIn: DateFns.differenceInDays(activeFrom, props.dateNow),
      expiresIn: DateFns.differenceInDays(expiresAt, props.dateNow),
    },
    {
      id: 'hours',
      availableIn: DateFns.differenceInHours(activeFrom, props.dateNow),
      expiresIn: DateFns.differenceInHours(expiresAt, props.dateNow),
    },
    {
      id: 'minutes',
      availableIn: DateFns.differenceInMinutes(activeFrom, props.dateNow),
      expiresIn: DateFns.differenceInMinutes(expiresAt, props.dateNow),
    },
  ]

  return {
    timeLeft: props.active
      ? R.find(R.pathSatisfies(R.lt(0), ['expiresIn']), timeData)
      : R.find(R.pathSatisfies(R.lt(0), ['availableIn']), timeData),
  }
}

getTimeLeftForPromotion.propTypes = {
  timeLeft: PropTypes.shape({
    availableIn: PropTypes.number,
    expiresIn: PropTypes.number,
    id: PropTypes.oneOf(['days', 'hours', 'minutes']),
  }),
  dateNow: PropTypes.string,
  expiresAt: PropTypes.string,
  activeFrom: PropTypes.string,
}

function getTranslationKey(props) {
  switch (props.type?.toLowerCase()) {
    case 'freespins': {
      return `inventory.${Herz.Utils.Strings.toKebabCase(props.type)}`
    }

    case 'featuretriggers': {
      return `inventory.${Herz.Utils.Strings.toKebabCase(props.type)}${
        props.zeroWagering ? '.zero-wager' : ''
      }`
    }

    default: {
      return `inventory.${Herz.Utils.Strings.toKebabCase(props.type)}`
    }
  }
}

const END_TIME_TEXT_COLOR = {
  day: 'g-text',
  hour: 'textDanger',
  minutes: 'red',
}

export function InventoryCard(props) {
  const dispatch = ReactRedux.useDispatch()
  const [isFetching, setFetching] = React.useState(false)
  const { timeLeft } = getTimeLeftForPromotion(props)
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate(
    () => [
      'inventory.time-left.days.available-in',
      'inventory.time-left.hours.available-in',
      'inventory.time-left.minutes.available-in',
      'inventory.time-left.undefined.available-in',
      'inventory.time-left.undefined.expires-in',
      'inventory.time-left.days.expires-in',
      'inventory.time-left.hours.expires-in',
      'inventory.time-left.minutes.expires-in',
      'inventory.not-available.client-type',
      'inventory.use',
      // TODO: List all possible types in `preload`
      `inventory.${Herz.Utils.Strings.toKebabCase(props.type)}.image`,
      // TODO: Fetch from GraphQL or Casino Saga
      `${getTranslationKey(props)}.description`,
      // TODO: Fetch from GraphQL or Casino Saga
      `${getTranslationKey(props)}.title`,
    ],
    [props.type]
  )

  const disabled = !props.active || props.gameDisabledForClientType
  const imageUrl =
    props.gameImage ||
    translate(`inventory.${Herz.Utils.Strings.toKebabCase(props.type)}.image`)

  function handleActivate() {
    setFetching(true)
    dispatch(
      // eslint-disable-next-line react-hooks/rules-of-hooks
      Api.useItem(props.id, {
        success: res => {
          const item = res.value
          setFetching(false)
          if (
            ['Freespins', 'FeatureTriggers', 'FreeGamesVoucher'].includes(
              item.type
            )
          ) {
            history.push(
              `/casino/games/${item.gameId}?free_games=${item.freeGames}`
            )
            return Inventory.remove(item.id)
          } else if (item?.type === 'Freebet') {
            history.push('/sports')
            return Inventory.remove(item.id)
          } else {
            return Inventory.remove(item.id)
          }
        },
        failure: () => {
          setFetching(false)
          return Notifications.add({
            message: 'errors.use-inventory-item.failed',
            level: 'error',
          })
        },
        version: 1,
      })
    )
  }

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        color="g-text"
        borderRadius={0}
        display="flex"
        flexDirection="column"
        p={1}
        borderLeft="10px solid"
        borderColor={props.seen ? 'disabled' : 'unseen-inventory-border'}
        overflow="hidden"
      >
        <Common.Box display="flex" alignItems="center">
          <Common.Box
            flexShrink="0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Common.Box
              borderRadius={0}
              backgroundImage={`url(${imageUrl})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundColor="disabled"
              height="60px"
              width="60px"
              flexShrink="0"
              overflow="hidden"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ filter: disabled ? 'grayscale(1)' : 'grayscale(0)' }}
            ></Common.Box>
            <Common.Box
              fontSize={0}
              pt={0}
              color={
                props.active ? END_TIME_TEXT_COLOR[timeLeft?.id] : 'g-text'
              }
              textAlign="center"
              maxWidth="60px"
            >
              {props.active
                ? translate(`inventory.time-left.${timeLeft?.id}.expires-in`, {
                    timeLeft: timeLeft?.expiresIn,
                  })
                : translate(
                    `inventory.time-left.${timeLeft?.id}.available-in`,
                    { timeLeft: timeLeft?.availableIn }
                  )}
            </Common.Box>
          </Common.Box>
          <Common.Box
            pl={1}
            display="inline-flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Common.Box
              pb="4px"
              fontFamily="head"
              fontSize={2}
              fontWeight="bold"
            >
              {translate(`${getTranslationKey(props)}.title`, {
                amount: props.amount,
              })}
            </Common.Box>
            <Common.Text fontSize={1}>
              <HtmlContent
                html={{
                  __html: translate(`${getTranslationKey(props)}.description`, {
                    amount: props.amount,
                    betAmountCents: props.betAmountCents,
                    gameTitle: props.gameTitle,
                    gameProvider: props.gameProvider,
                    spinMinValue: props.spinValueMinMax?.minValue,
                    spinMaxValue: props.spinValueMinMax?.maxValue,
                  }),
                }}
              />
            </Common.Text>

            {!disabled && (
              <Common.Box flexGrow="1" pt={1}>
                <Button
                  stretch
                  disabled={isFetching}
                  variant="primary"
                  fontSize={1}
                  onClick={handleActivate}
                >
                  {isFetching ? <Icons.Spinner /> : translate('inventory.use')}
                </Button>
              </Common.Box>
            )}
          </Common.Box>
        </Common.Box>
        {props.gameDisabledForClientType && (
          <React.Fragment>
            <Common.Box py={1}>
              <Divider />
            </Common.Box>
            <Common.Box
              fontSize={1}
              color="info"
              fontStyle="italic"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Common.Box pr="4px" fontSize={2} display="inline-flex">
                <Icons.Info />
              </Common.Box>
              {translate('inventory.not-available.client-type')}
            </Common.Box>
          </React.Fragment>
        )}
      </Common.Box>
    </Theming.Alternative>
  )
}

InventoryCard.propTypes = {
  amount: PropTypes.number,
  active: PropTypes.bool,
  betAmountCents: PropTypes.number,
  gameImage: PropTypes.string,
  gameTitle: PropTypes.string,
  gameProvider: PropTypes.string,
  gameDisabledForClientType: PropTypes.bool,
  seen: PropTypes.bool,
  spinValueMinMax: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.string,
}
